import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { DateTime } from "luxon";
import { BehaviorSubject, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { hashCode } from "../../../lib/lib";
import { LogsService } from "../../api";
import { LogsFilterDto } from "../../api/model/logsFilterDto";
import { LogsFilterComponent } from "../logs-filter/logs-filter.component";
import { SelectTimeRangeComponent, SelectTimeRangeResult } from "./select-time-range/select-time-range.component";
import { SelectZombieActionComponent, ZombieActionResult } from "./select-zombie-action/select-zombie-action.component";

@Component({
  selector: "app-logs-list",
  templateUrl: "./logs-list.component.html",
  styleUrls: ["./logs-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogsListComponent implements OnInit, OnChanges {
  @Input() zombieId?: number;
  @Input() dateStart?: string;
  @Input() dateEnd?: string;
  @Input() likeSearch?: string;
  @Input() limit: number = 100;
  @Input() highlight?: string;

  @ViewChild(LogsFilterComponent)
  filterComp!: LogsFilterComponent;

  initFilterParams: LogsFilterDto = { limit: 100 };
  @Output() filter = new BehaviorSubject<LogsFilterDto>(this.initFilterParams);
  loading = true;

  list$ = this.filter.pipe(
    tap(() => (this.loading = true)),
    switchMap(data => {
      return this.api.list(data.zombieId, data.dateStart, data.dateEnd, data.likeSearch, data.limit);
    }),
    catchError(err => {
      if (err instanceof HttpErrorResponse) {
        this.snackBar.open(JSON.stringify(err.error), "ok");
      } else {
        this.snackBar.open(err.message, "ok");
      }
      return of([]);
    }),
    map(rs =>
      rs.map((r: any) => ({
        ...r,
        parsed: this.parseMessage(r.message),
        highlight: this.highlight === String(hashCode(r.ts + r.message))
      }))
    ),
    tap(() => (this.loading = false))
  );

  constructor(
    private api: LogsService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private matBottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.initFilterParams = {
      limit: this.limit,
      zombieId: this.zombieId,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      likeSearch: this.likeSearch
    };
  }

  update(ev: any) {
    let data = {
      ...ev,
      dateStart: ev.dateStart instanceof Date ? ev.dateStart.toISOString() : ev.dateStart,
      dateEnd: ev.dateEnd instanceof Date ? ev.dateEnd.toISOString() : ev.dateEnd
    };
    this.filter.next(data);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.filter.next({
      limit: this.limit,
      zombieId: this.zombieId,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
      likeSearch: this.likeSearch
    });
  }

  parseMessage(value: string) {
    let rs = value.match(/^\[(?<time>[\d\s\-:,]+)] (?<level>[\w]+):(?<logger>[\w\.]+):(?<message>.+)/);
    if (!rs) {
      return {
        time: undefined,
        level: undefined,
        logger: undefined,
        message: value
      };
    }
    return {
      time: rs.groups!["time"],
      level: rs.groups!["level"],
      logger: rs.groups!["logger"],
      message: rs.groups!["message"]
    };
  }

  selectZombie(zombieId: number) {
    this.matBottomSheet
      .open<SelectZombieActionComponent, any, ZombieActionResult>(SelectZombieActionComponent)
      .afterDismissed()
      .subscribe(rs => {
        if (rs === ZombieActionResult.FILTER) {
          this.filterComp.form.patchValue({ zombieId });
        }
        if (rs === ZombieActionResult.OPEN_ZOMBIE_PAGE) {
          const url = this.router.serializeUrl(this.router.createUrlTree(["/zombies/z", zombieId]));
          window.open(url, "_blank");
        }
        if (rs === ZombieActionResult.OPEN_ZOMBIE_LOGS_PAGE) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(["/logs"], {
              queryParams: {
                ...this.filter.getValue(),
                zombieId
              }
            })
          );
          window.open(url, "_blank");
        }
      });
  }

  selectTimeRange(item: any) {
    console.log(item);
    this.matBottomSheet
      .open<SelectTimeRangeComponent, any, SelectTimeRangeResult>(SelectTimeRangeComponent)
      .afterDismissed()
      .subscribe(rs => {
        let queryParams = null;
        if (rs === SelectTimeRangeResult.BEFORE) {
          queryParams = {
            zombieId: item.zombie_id,
            dateStart: DateTime.fromISO(item.ts)
              .minus({ minutes: 5 })
              .toUTC()
              .toISO(),
            dateEnd: item.ts
          };
        }

        if (rs === SelectTimeRangeResult.AFTER) {
          queryParams = {
            zombieId: item.zombie_id,
            dateEnd: DateTime.fromISO(item.ts)
              .plus({ minutes: 1 })
              .toUTC()
              .toISO(),
            dateStart: item.ts
          };
        }

        if (rs === SelectTimeRangeResult.AROUND) {
          queryParams = {
            zombieId: item.zombie_id,
            dateStart: DateTime.fromISO(item.ts)
              .minus({ minutes: 1 })
              .toUTC()
              .toISO(),
            dateEnd: DateTime.fromISO(item.ts)
              .plus({ minutes: 1 })
              .toUTC()
              .toISO()
          };
        }

        if (queryParams) {
          const hc = hashCode(item.ts + item.message);
          const url = this.router.serializeUrl(
            this.router.createUrlTree(["/logs"], {
              queryParams: {
                ...queryParams,
                highlight: hc
              }
            })
          );
          console.log(url, queryParams);
          window.open(url, "_blank");
        }
      });
  }
}
