import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";

export enum SelectTimeRangeResult {
  BEFORE = "BEFORE",
  AFTER = "AFTER",
  AROUND = "AROUND"
}

@Component({
  selector: "app-select-time-range",
  templateUrl: "./select-time-range.component.html",
  styleUrls: ["./select-time-range.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectTimeRangeComponent implements OnInit {
  constructor(private _bottomSheetRef: MatBottomSheetRef<SelectTimeRangeComponent>) {}

  ngOnInit(): void {}

  openBefore() {
    this._bottomSheetRef.dismiss(SelectTimeRangeResult.BEFORE);
  }

  openAfter() {
    this._bottomSheetRef.dismiss(SelectTimeRangeResult.AFTER);
  }

  openAround() {
    this._bottomSheetRef.dismiss(SelectTimeRangeResult.AROUND);
  }
}
