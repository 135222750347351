<div>
  <h1>Lag</h1>
</div>
<ng-template #loading>
  <app-inline-loader></app-inline-loader>
</ng-template>


<div style="margin-bottom: 100px">
  <app-stats-chart *ngIf="viewsHoursLag else loading"
                   chartTitle="Відставання переглядів по годинах"
                   description="Скільки хвилин відстає завантаження переглядів від запланованого інтервалу. До 10 хв нормально"
                   exportTitle="Views hour lag"
                   [series]="viewsHoursLag">
    <mat-select style="width: 100px" [value]="groupCurrHours" (valueChange)="loadHours($event)">
      <mat-option [value]="group.Day">Days</mat-option>
      <mat-option [value]="group.Hour">Hours</mat-option>
      <mat-option [value]="group.Minute">Minutes</mat-option>
    </mat-select>

  </app-stats-chart>
</div>

<div style="margin-bottom: 100px">
  <app-stats-chart *ngIf="viewsDaysLag else loading"
                   chartTitle="Відставання переглядів по днях"
                   description="Скільки хвилин відстає завантаження переглядів від запланованого інтервалу. До 3 годин на максимумах нормально"
                   exportTitle="Views days lag"
                   [series]="viewsDaysLag">
    <mat-select style="width: 100px" [value]="groupCurrDays" (valueChange)="loadDays($event)">
      <mat-option [value]="group.Day">Days</mat-option>
      <mat-option [value]="group.Hour">Hours</mat-option>
      <mat-option [value]="group.Minute">Minutes</mat-option>
    </mat-select>
  </app-stats-chart>
</div>

<div style="margin-bottom: 100px">
  <app-stats-chart *ngIf="messagesNotSaved else loading"
                   chartTitle="Кількість не збереженни постів"
                   description="Спеціальний таск перевіряє раз на добу останні 20 повідомлень кожного каналу.
                   І якщо якогось з повідомлень немає в базі воно буде га графіку і збережеться в базу. Враховуються повідомлення до 10 днів давності."
                   exportTitle="Кількість не збереженни постів"
                   [series]="messagesNotSaved">
    <mat-select style="width: 100px" [value]="groupCurrMessages" (valueChange)="loadNotSavedMessages($event)">
      <mat-option [value]="group.Day">Days</mat-option>
      <mat-option [value]="group.Hour">Hours</mat-option>
      <mat-option [value]="group.Minute">Minutes</mat-option>
    </mat-select>
  </app-stats-chart>
</div>
