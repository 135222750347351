import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Title } from "@angular/platform-browser";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ZombiesService } from "../../api";

@UntilDestroy()
@Component({
  selector: "app-zombies-page",
  templateUrl: "./zombies-page.component.html",
  styleUrls: ["./zombies-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZombiesPageComponent implements OnInit, AfterViewInit {
  fullResponse: any[] = [];
  columnsToDisplay = [
    "active",
    "_id",
    "name",
    "createdAt",
    "last_work_done",
    "dialogs_count",
    "proxy",
    "avg_loop",
    "tags"
  ];
  columnsCtrl = new FormControl(this.columnsToDisplay);

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  filter = (i: any) => i.active;
  sortField = "createdAt";
  sortDirection = "desc";

  dataForRender: any[] = [];
  itemsCount = 0;
  onlineCount = 0;

  constructor(private api: ZombiesService, private cd: ChangeDetectorRef, private title: Title) {}

  get dataFilteredSorted() {
    return this.fullResponse.filter(this.filter).sort((a, b) => {
      const valA = a[this.sortField];
      const valB = b[this.sortField];
      if (valA === valB) {
        return 0;
      }
      if (["last_work_done", "createdAt"].indexOf(this.sortField) >= 0) {
        if (!valA) {
          return this.sortDirection === "desc" ? 1 : -1;
        }
        if (!valB) {
          return this.sortDirection === "desc" ? -1 : 1;
        }
        if (this.sortDirection === "desc") {
          return new Date(valB).getTime() - new Date(valA).getTime();
        }
        return new Date(valA).getTime() - new Date(valB).getTime();
      }

      if (["last_work_done", "group_count", "avg_loop"].indexOf(this.sortField) >= 0) {
        if (!isFinite(valA)) {
          return this.sortDirection === "desc" ? 1 : -1;
        }
        if (!isFinite(valB)) {
          return this.sortDirection === "desc" ? -1 : 1;
        }
        if (this.sortDirection === "desc") {
          return valB - valA;
        }
        return valA - valB;
      }

      if (this.sortDirection === "desc") {
        return valA < valB ? 1 : -1;
      }
      return valA < valB ? -1 : 1;
    });
  }

  ngOnInit(): void {
    this.title.setTitle("Zombies list");
  }

  prepareData() {
    let items = this.dataFilteredSorted;
    this.itemsCount = items.length;
    // 20 minutes
    this.onlineCount = items.filter(
      i => i.last_work_done && Date.now() - new Date(i.last_work_done).getTime() < 25 * 60 * 1000
    ).length;
    this.dataForRender = items.slice(
      this.paginator.pageIndex * this.paginator.pageSize,
      (this.paginator.pageIndex + 1) * this.paginator.pageSize
    );
  }

  reloadFromApi() {
    this.api
      .getZombies()
      .pipe(untilDestroyed(this))
      .subscribe(rs => {
        this.fullResponse = rs;
        this.prepareData();
        this.cd.detectChanges();
      });
  }

  public ngAfterViewInit() {
    this.reloadFromApi();
    this.sort.sortChange.subscribe(rs => {
      this.paginator.pageIndex = 0;
      this.sortField = rs.active;
      this.sortDirection = rs.direction;
      this.prepareData();
      this.cd.detectChanges();
    });
    this.paginator.page.subscribe(rs => {
      this.prepareData();
      this.cd.detectChanges();
    });
  }
}
