import { HttpClientModule } from "@angular/common/http";
import "@angular/common/locales/global/uk";
import { LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { ApiModule, Configuration } from "./api";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { ChatsPageComponent } from "./pages/chats-page/chats-page.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { LogsPageComponent } from "./pages/logs-page/logs-page.component";
import { ZombiePageComponent } from "./pages/zombie-page/zombie-page.component";
import { ZombiesPageComponent } from "./pages/zombies-page/zombies-page.component";
import { RequestsPageComponent } from "./pages/requests-page/requests-page.component";
import { SettingsPageComponent } from "./pages/settings-page/settings-page.component";
import { StatusPageComponent } from "./pages/status-page/status-page.component";
import { LagPageComponent } from "./pages/lag-page/lag-page.component";
import { ZombieEditPageComponent } from "./pages/zombie-edit-page/zombie-edit-page.component";

@NgModule({
  declarations: [
    AppComponent,
    DashboardPageComponent,
    ZombiesPageComponent,
    ZombiePageComponent,
    LogsPageComponent,
    ChatsPageComponent,
    RequestsPageComponent,
    SettingsPageComponent,
    StatusPageComponent,
    LagPageComponent,
    ZombieEditPageComponent
  ],
  imports: [
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    HttpClientModule,
    ComponentsModule,
    BrowserModule,
    AppRoutingModule,
    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: "/api",
          apiKeys: {}
        })
    ),
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatTabsModule,
    MatInputModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: "uk" }],
  bootstrap: [AppComponent]
})
export class AppModule {}
