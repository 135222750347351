import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { RequestsFilterDto } from "../../../lib/types";

@Component({
  selector: "app-requests-filter",
  templateUrl: "./requests-filter.component.html",
  styleUrls: ["./requests-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestsFilterComponent implements OnInit {
  form: FormGroup = new FormGroup({
    zombieId: new FormControl(),
    dateStart: new FormControl(),
    dateEnd: new FormControl(),
    requestLike: new FormControl(),
    method: new FormControl(),
    takeMs: new FormControl(),
    error: new FormControl(),
    limit: new FormControl(100)
  });

  @Input() initParams!: RequestsFilterDto;

  @Output() formChanged = this.form.valueChanges.pipe(debounceTime(1000));

  constructor() {}

  ngOnInit(): void {
    this.form.patchValue(this.initParams, { emitEvent: false });
  }
}
