<ng-template #loading>
  <app-inline-loader></app-inline-loader>
</ng-template>
<div class="content">
  <div *ngFor="let comp of components" class="mat-elevation-z2 card">
    <div class="title">{{comp.title}}</div>
    <table class="data-table" style="width: 100%" *ngIf="comp.req$ | async as data; else loading">
      <tr *ngFor="let item of data.slice(0, comp.limit)">
        <td>{{item.displayName}}</td>
        <td title="{{item.value}}">{{item.hrValue}}</td>
      </tr>
      <tr *ngIf="data.length > comp.limit">
        <td colspan="2" style="text-align: center">
          <button mat-flat-button (click)="comp.limit = data.length">еще</button>
        </td>
      </tr>
    </table>
  </div>
</div>

<br>
<br>
<ng-template #loading>
  <app-inline-loader></app-inline-loader>
</ng-template>

<div>
  <app-stats-chart *ngIf="loopChartData else loading"
                   chartTitle="Loop time"
                   description="Скільки часу в середньому витрачає зомбі на один цикл тасків"
                   exportTitle="All zombies"
                   [series]="loopChartData"></app-stats-chart>
</div>
<div>
  <app-stats-chart *ngIf="requestsCountChartData else loading"
                   chartTitle="Requests count"
                   description="Кількість запипів до апі телеграм"
                   exportTitle="All zombies"
                   [series]="requestsCountChartData"></app-stats-chart>
</div>
<div>
  <app-stats-chart *ngIf="requestsTimeChartData else loading"
                   chartTitle="Request time"
                   description="Середні час затрачений на запит"
                   exportTitle="All zombies"
                   [series]="requestsTimeChartData"></app-stats-chart>
</div>

<div>
  <app-stats-chart *ngIf="joinChatsGraph else loading"
                   chartTitle="Підписались щоб слідкувати"
                   description="Кількість чатів на які підписались для того щоб слідкувати,
                   до цього ми могли вже знати про ці чати, чат міг просто злетіти, зомбі забанити"
                   exportTitle="Нових чатів"
                   [series]="joinChatsGraph"></app-stats-chart>
</div>

<div>
  <app-stats-chart *ngIf="createdChatsGraph else loading"
                   chartTitle="Нових чатів"
                   description="Кількість чатів на які підписались і не знали про них до цього"
                   exportTitle="Нових чатів"
                   [series]="createdChatsGraph"></app-stats-chart>
</div>

