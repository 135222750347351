import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { ApiModule } from "../api";
import { PipesModule } from "../pipes/pipes.module";
import { InlineLoaderComponent } from "./inline-loader/inline-loader.component";
import { LogsFilterComponent } from "./logs-filter/logs-filter.component";
import { LogsListComponent } from "./logs-list/logs-list.component";
import { StatsChartComponent } from "./stats-chart/stats-chart.component";
import { SelectZombieActionComponent } from "./logs-list/select-zombie-action/select-zombie-action.component";
import { SelectTimeRangeComponent } from "./logs-list/select-time-range/select-time-range.component";
import { RequestsListComponent } from "./requests-list/requests-list.component";
import { RequestsFilterComponent } from "./requests-filter/requests-filter.component";
import { ChatsListComponent } from "./chats-list/chats-list.component";

@NgModule({
  declarations: [
    InlineLoaderComponent,
    StatsChartComponent,
    LogsListComponent,
    LogsFilterComponent,
    SelectZombieActionComponent,
    SelectTimeRangeComponent,
    RequestsListComponent,
    RequestsFilterComponent,
    ChatsListComponent
  ],
  exports: [
    InlineLoaderComponent,
    StatsChartComponent,
    LogsListComponent,
    LogsFilterComponent,
    RequestsListComponent,
    ChatsListComponent
  ],
  imports: [
    ApiModule,
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatBottomSheetModule,
    PipesModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule
  ]
})
export class ComponentsModule {}
