<app-logs-filter (formChanged)="update($event)" [initParams]="initFilterParams"></app-logs-filter>
<div style="position: relative; min-height: 200px">
  <div *ngIf="loading" class="loader-overlay">
    <app-inline-loader ></app-inline-loader>
  </div>
  <table *ngIf="list$ | async as items">
    <thead>
    <tr>
      <th width="100">Date</th>
      <th width="100">Zombie(service)</th>
      <th>Message</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of items" class="level-{{item.parsed.level}}" [class.highlight]="item.highlight">
      <td class="date" (click)="selectTimeRange(item)">{{item.ts | date: 'short'}}</td>
      <td class="zombie" (click)="selectZombie(item.zombie_id)">{{item.zombie_id}}</td>
      <td class="message" (click)="full.style.display = 'inline'; parsed.style.display = 'none'">
        <span #parsed>{{item.parsed.message}}</span>
        <pre style="display: none; white-space: pre-wrap;" #full>{{item.message}}</pre>
      </td>
    </tr>
    </tbody>
  </table>
</div>
