<div class="graph-widget">
  <h2>{{chartTitle}}</h2>
  <p>
    <mat-hint>{{description}}</mat-hint>
  </p>
  <div class="heading">
    <div #range></div>
    <span style="flex: 1 1 auto;"></span>
    <ng-content></ng-content>
    <button (click)="exportMenu()" mat-icon-button *ngIf="!emptyData">
      <mat-icon>get_app</mat-icon>
    </button>
  </div>
  <div style="max-height: 400px;" #divElement></div>
  <p *ngIf="emptyData">Недостаточно данных</p>
</div>

<ng-template #contextMenu>
  <mat-nav-list>
    <mat-list-item (click)="export('png')"> Скачать PNG </mat-list-item>
    <mat-list-item (click)="export('csv')"> Скачать CSV </mat-list-item>
    <mat-list-item (click)="export('xls')"> Скачать XLS </mat-list-item>
  </mat-nav-list>
</ng-template>
