export * from './chats.service';
import { ChatsService } from './chats.service';
export * from './collectorConfig.service';
import { CollectorConfigService } from './collectorConfig.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './lag.service';
import { LagService } from './lag.service';
export * from './logs.service';
import { LogsService } from './logs.service';
export * from './requests.service';
import { RequestsService } from './requests.service';
export * from './status.service';
import { StatusService } from './status.service';
export * from './zombie.service';
import { ZombieService } from './zombie.service';
export * from './zombies.service';
import { ZombiesService } from './zombies.service';
export const APIS = [ChatsService, CollectorConfigService, DashboardService, DefaultService, LagService, LogsService, RequestsService, StatusService, ZombieService, ZombiesService];
