import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LogsFormatPipe } from "./logs-format.pipe";

@NgModule({
  declarations: [LogsFormatPipe],
  imports: [CommonModule],
  exports: [LogsFormatPipe]
})
export class PipesModule {}
