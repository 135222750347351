import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { GetChatsFilterDto } from "../../../lib/types";
import { ChatsService } from "../../api";

export class ChatsDataSource implements DataSource<any> {
  private chatsSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private api: ChatsService, private snackBar: MatSnackBar) {}

  public connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.chatsSubject.asObservable();
  }

  public disconnect(collectionViewer: CollectionViewer): void {
    this.chatsSubject.complete();
    this.loadingSubject.complete();
  }

  loadChats(filter: GetChatsFilterDto) {
    this.loadingSubject.next(true);

    this.api
      .list(filter.zombieId, filter.notTracked, filter.skip, filter.limit, filter.sortField, filter.sortDirection)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            this.snackBar.open(JSON.stringify(err.error), "ok");
          } else {
            this.snackBar.open(err.message, "ok");
          }
          return of([]);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(items => this.chatsSubject.next(items));
  }
}

@Component({
  selector: "app-chats-list",
  templateUrl: "./chats-list.component.html",
  styleUrls: ["./chats-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatsListComponent implements OnInit, AfterViewInit {
  @Input() zombieId?: number;
  @Input() notTracked?: boolean;
  @Input() sortField?: string;
  @Input() sortDirection?: string;
  @Input() skip: number = 0;
  @Input() limit: number = 100;

  initFilterParams: GetChatsFilterDto = { limit: 100 };
  @Output() filter = new BehaviorSubject<GetChatsFilterDto>(this.initFilterParams);

  dataSource!: ChatsDataSource;

  columnsToDisplay = [
    "chat",
    "zombie",
    "members",
    "joinedAt",
    "postsCount",
    "notTracked",
    "updated",
    "restrictionReason"
  ];
  columnsCtrl = new FormControl(this.columnsToDisplay);

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  sortFieldsMap = {
    chat: "_id",
    zombie: "zombie_id",
    members: "participants_count",
    postsCount: "last_10_days_posts",
    notTracked: "not_tracked_reason",
    updated: "update_full_info_in",
    restrictionReason: "restriction_reason"
  } as any;

  constructor(private api: ChatsService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.dataSource = new ChatsDataSource(this.api, this.snackBar);
    this.initFilterParams = {
      zombieId: this.zombieId,
      notTracked: this.notTracked,
      skip: this.skip,
      limit: this.limit,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    };
    this.filter.asObservable().subscribe(data => this.dataSource.loadChats(data));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.filter.next({
      zombieId: this.zombieId,
      notTracked: this.notTracked,
      skip: this.skip,
      limit: this.limit,
      sortField: this.sortField,
      sortDirection: this.sortDirection
    });
  }

  public ngAfterViewInit() {
    this.sort.sortChange.subscribe(rs => {
      this.paginator.pageIndex = 0;
      this.sortField = rs.active;
      this.sortDirection = rs.direction;
      this.filter.next({
        ...this.filter.getValue(),
        sortField: this.sortFieldsMap[rs.active],
        sortDirection: rs.direction,
        skip: 0,
        limit: this.paginator.pageSize
      });
    });
    this.paginator.page.subscribe(rs => {
      this.filter.next({
        ...this.filter.getValue(),
        skip: this.paginator.pageIndex * this.paginator.pageSize,
        limit: this.paginator.pageSize
      });
    });
  }

  update(ev: any) {
    let data = {
      ...ev
    };
    this.filter.next(data);
  }
}
