<div>
  <h1>Collector components status</h1>
</div>

<ng-template #loading>
  <app-inline-loader [diameter]="20" [strokeWidth]="2"></app-inline-loader>
</ng-template>

<div class="mat-elevation-z2 card">
  <div class="title">Components</div>
  <table class="data-table" style="width: 100%">
    <thead>
    <tr>
      <th>Service</th>
      <th>Status</th>
    </tr>
    </thead>
    <tr>
      <td>Zombies</td>
      <td>
        <div *ngIf="reqZombies$ | async as data; else loading"
             [class.ok-status]="data.status === 'ok'">{{data | json}}</div>
      </td>
    </tr>
    <tr>
      <td>Night stats</td>
      <td>
        <div *ngIf="reqNightStats$ | async as data; else loading"
             [class.ok-status]="data.status === 'ok'">{{data | json}}</div>
      </td>
    </tr>
    <tr>
      <td>Messages parser</td>
      <td>
        <div *ngIf="reqMessagesParser$ | async as data; else loading"
             [class.ok-status]="data.status === 'ok'">{{data | json}}</div>
      </td>
    </tr>
    <tr *ngFor="let serviceName of services">
      <td>{{serviceName}}</td>
      <td>
        <div *ngIf="servicesStatus[serviceName]; else loading"
             [class.ok-status]="servicesStatus[serviceName].status === 'ok'">{{servicesStatus[serviceName]  | json}}</div>
      </td>
    </tr>
    <tr *ngFor="let buff of buffers">
      <td>{{buff}}</td>
      <td>
        <div *ngIf="buffSizes[buff]; else loading"
             [class.ok-status]="buffSizes[buff].status === 'ok'">{{buffSizes[buff] | json}}</div>
      </td>
    </tr>
  </table>
</div>
