<div class="header">
  <h1 style="margin: 0">#{{zombie._id}}</h1>
  <span class="spacer"></span>
  <button mat-stroked-button color="primary" *ngIf="zombie.active" routerLink="/zombies/z/{{zombie._id}}/edit">
    Edit
  </button>
  <button mat-stroked-button style="color: green; margin-left: 16px" *ngIf="zombie.active" (click)="disable()">Active</button>
  <button mat-stroked-button style="color: red; margin-left: 16px" *ngIf="!zombie.active">Inactive</button>

  <button mat-stroked-button style="color: green; margin-left: 16px" *ngIf="isRunning">Running</button>
  <button mat-stroked-button style="color: red; margin-left: 16px" *ngIf="!isRunning">Down</button>
  <button mat-stroked-button style="color: red; margin-left: 16px" (click)="releaseChats()">Release chats</button>
</div>
<div class="basic-badges">
  <div class="mini-card">
    <h3 class="heading">Трекає</h3>
    <div class="card-data">{{zombie.trackedChats}}</div>
  </div>
  <div class="mini-card">
    <h3 class="heading">Діалогів</h3>
    <div class="card-data">
      {{zombie.dialogs_count}} /
      <span matTooltip="Активних (до 2 дні повідомлення)">{{zombie.active_dialogs_count}}</span></div>
  </div>
  <div class="mini-card">
    <h3 class="heading">Каналів</h3>
    <div class="card-data">{{zombie.channels_count}}</div>
  </div>
  <div class="mini-card">
    <h3 class="heading">Групп</h3>
    <div class="card-data">{{zombie.group_count}}</div>
  </div>
  <div class="mini-card">
    <h3 class="heading">Disabled chats</h3>
    <div class="card-data">{{zombie.chats_banned}}</div>
  </div>
  <div class="mini-card">
    <h3 class="heading">Avg Loop (seconds)</h3>
    <div class="card-data">
      <span matTooltip="Last 24 hour">{{zombie.loopTimes.days_1}}</span> /
      <span matTooltip="Last 7 days">{{zombie.loopTimes.days_7}}</span> /
      <span matTooltip="Last 30 days">{{zombie.loopTimes.days_30}}</span>
    </div>
  </div>
  <div class="mini-card">
    <h3 class="heading">Proxy</h3>
    <div class="card-data">{{zombie.proxy?.addr}}</div>
  </div>
</div>

<ng-template #loading>
  <app-inline-loader></app-inline-loader>
</ng-template>

<div class="flex-row">
  <app-stats-chart *ngIf="loopChartData else loading"
                   chartTitle="Loop time"
                   description="Скільки часу в середньому витрачає зомбі на один цикл тасків"
                   exportTitle="zombie: {{zombie._id}}"
                   [series]="loopChartData"></app-stats-chart>

  <app-stats-chart *ngIf="requestsCountChartData else loading"
                   chartTitle="Requests count"
                   description="Кількість запипів до апі телеграм"
                   exportTitle="zombie: {{zombie._id}}"
                   [series]="requestsCountChartData"></app-stats-chart>
</div>

<div class="flex-row">
  <app-stats-chart *ngIf="requestsTimeChartData else loading"
                   chartTitle="Request time"
                   description="Середні час затрачений на запит"
                   exportTitle="zombie: {{zombie._id}}"
                   [series]="requestsTimeChartData"></app-stats-chart>
  <div style="width: 49%">
    <h2> Top requests 24 Hours</h2>
    <table class="last-req" *ngIf="topRequests !== 'loading' else loading" style="width: 100%">
      <thead>
      <tr>
        <th>Method</th>
        <th>Count</th>
        <th>Errors</th>
      </tr>
      </thead>
      <tr *ngFor="let item of topRequests">
        <td>{{item.method}}</td>
        <td>{{item.count}}</td>
        <td>{{item.errors_count}}</td>
      </tr>
    </table>
  </div>
</div>

<div>
  <mat-tab-group>
    <mat-tab label="Logs">
      <ng-template matTabContent>
        <div style="padding: 16px 0;">
          <app-logs-list [zombieId]="zombie._id" [limit]="2000"></app-logs-list>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Requests">
      <ng-template matTabContent>
        <div style="padding: 16px 0;">
          <app-requests-list [zombieId]="zombie._id" [limit]="2000"></app-requests-list>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Chats">
      <ng-template matTabContent>
        <div style="padding: 16px 0;">
          <app-chats-list [zombieId]="zombie._id" [limit]="2000"></app-chats-list>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<br>
<br>
<br>
<br>
<br>
<br>
