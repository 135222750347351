<div class="table-wrapper mat-elevation-z2">
  <table mat-table [dataSource]="dataSource" matSort matSortActive="joined_at" matSortDisableClear
         matSortDirection="desc">

    <ng-container matColumnDef="chat">
      <th mat-header-cell *matHeaderCellDef>Chat</th>
      <td mat-cell *matCellDef="let element">
        <div class="flex-chat">
          <img src="data:image/jpeg;base64,{{element.photo_bytes}}" *ngIf="element.photo_bytes">
          <div>
            <div class="chat-title"><b>{{element.title}}</b></div>
            <div>
              <span style="color: #a3a3a3">{{element.type}} </span>
              <span *ngIf="element.username">@{{element.username}}</span>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="zombie">
      <th mat-header-cell *matHeaderCellDef> Zombie</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['/zombies/z', element.zombie_id]" target="_blank">{{element.zombie_id}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="members">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Members</th>
      <td mat-cell *matCellDef="let element"> {{element.participants_count | number}} </td>
    </ng-container>

    <ng-container matColumnDef="joinedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Created/Joined</th>
      <td mat-cell *matCellDef="let element">
        {{element.created_at | date: 'short'}} <br>
        / {{element.joined_at | date: 'short'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="postsCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Posts count</th>
      <td mat-cell *matCellDef="let element">
        {{element.last_day_posts}} / {{element.last_10_days_posts}}
      </td>
    </ng-container>

    <ng-container matColumnDef="notTracked">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> NotTracked</th>
      <td mat-cell *matCellDef="let element"> {{element.not_tracked_reason}} </td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Updated</th>
      <td mat-cell *matCellDef="let element"> {{element.update_full_info_in * 1000 | date: 'short'}} </td>
    </ng-container>

    <ng-container matColumnDef="restrictionReason">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear> Restrict</th>
      <td mat-cell *matCellDef="let element">
      <pre style="max-width: 200px; white-space: pre-wrap; overflow: auto">
          {{element.restriction_reason}}
        </pre>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef width="70">#</th>
      <td mat-cell *matCellDef="let element">
        <a mat-stroked-button [routerLink]="['c', element._id]" target="_blank">
          <mat-icon>arrow_forward</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsCtrl.value.concat(['action'])"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsCtrl.value.concat(['action']);"></tr>
  </table>
    <mat-paginator [length]="100000"
                   [pageSize]="100"
                   [pageSizeOptions]="[100, 500, 1000, 5000, 10000]"></mat-paginator>

  <div *ngIf="dataSource.loading$ | async" class="loader-overlay">
    <app-inline-loader></app-inline-loader>
  </div>
</div>
