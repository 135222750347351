import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { ZombieService } from "../../api";

@Component({
  selector: "app-zombie-page",
  templateUrl: "./zombie-page.component.html",
  styleUrls: ["./zombie-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZombiePageComponent implements OnInit {
  loopChartData: any = null;
  requestsCountChartData: any = null;
  requestsTimeChartData: any = null;

  topRequests: any = "loading";

  get zombie() {
    return this.route.snapshot.data.zombie;
  }

  get isRunning() {
    if (!this.zombie.last_work_done) {
      return false;
    }
    return Date.now() - new Date(this.zombie.last_work_done).getTime() < 60 * 60 * 1000;
  }

  constructor(
    private route: ActivatedRoute,
    private api: ZombieService,
    private cd: ChangeDetectorRef,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.title.setTitle(`Zombie ${this.zombie._id}`);
    this.api.getLoopChart(this.zombie._id).subscribe(rs => {
      this.loopChartData = [
        {
          name: "Продолжительность",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.time];
          }),
          type: "column",
          color: "rgb(155, 209, 40)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        }
      ];
      this.cd.detectChanges();
    });

    this.api.getRequestsTimeChart(this.zombie._id).subscribe(rs => {
      this.requestsTimeChartData = [
        {
          name: "милисекунд",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.time_ms];
          }),
          type: "column",
          color: "rgb(40,209,170)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        }
      ];
      this.cd.detectChanges();
    });

    this.api.getRequestsCountChart(this.zombie._id).subscribe(rs => {
      this.requestsCountChartData = [
        {
          name: "Запросы",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.all];
          }),
          type: "column",
          color: "rgb(40,141,209)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        },
        {
          name: "Ошибки",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), item.errors];
          }),
          type: "column",
          color: "rgb(173,43,43)",
          lineWidth: 2,
          tooltip: {
            valueDecimals: 0
          }
        }
      ];
      this.cd.detectChanges();
    });

    this.api.getRequestsTypeChart(this.zombie._id).subscribe((rs: any) => {
      this.topRequests = rs;
    });
  }

  releaseChats() {
    if (confirm("Ти впевнений що хочеш знати чати з трекінгу цим зомбі?")) {
      this.api.releaseChats(this.zombie._id).subscribe(rs => location.reload());
    }
  }

  disable() {
    if (confirm("Деактивировать зомби?")) {
      this.api.disableZombie(this.zombie._id).subscribe(rs => location.reload());
    }
  }
}
