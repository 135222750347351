import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CollectorConfigService } from "../../api";

@Component({
  selector: "app-settings-page",
  templateUrl: "./settings-page.component.html",
  styleUrls: ["./settings-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsPageComponent implements OnInit {
  saving = false;
  form = new FormGroup({
    subscriber_limit_participants_count: new FormControl(),
    un_subscriber_limit_participants_count: new FormControl(),
    zombie_chats_limit: new FormControl(),
    max_chats_on_worker: new FormControl(),
    flood_chat_threshold: new FormControl(),
    flood_chat_hard_threshold: new FormControl()
  });

  constructor(private api: CollectorConfigService, private cd: ChangeDetectorRef, private snack: MatSnackBar) {}

  ngOnInit(): void {
    this.api.get().subscribe(rs => {
      this.form.patchValue(rs);
    });
  }

  save() {
    this.saving = true;
    this.api.set(this.form.value).subscribe(rs => {
      this.form.markAsPristine();
      this.saving = false;
      this.cd.detectChanges();
      this.snack.open("Настройки збережені", "OK");
    });
  }
}
