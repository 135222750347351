<app-requests-filter (formChanged)="update($event)" [initParams]="initFilterParams"></app-requests-filter>
<div style="position: relative; min-height: 200px">
  <div *ngIf="loading" class="loader-overlay">
    <app-inline-loader></app-inline-loader>
  </div>
  <table *ngIf="list$ | async as items">
    <thead>
    <tr>
      <th width="100">Date</th>
      <th width="100">Zombie</th>
      <th width="120">Method</th>
      <th>Request</th>
      <th>Take(ms)</th>
      <th>Error</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of items">
      <td class="date" (click)="selectTimeRange(item)">{{item.ts | date: 'short'}}</td>
      <td class="zombie" (click)="selectZombie(item.zombie_id)">{{item.zombie_id}}</td>
      <td class="method">{{item.method}}</td>
      <td class="message">{{item.request}}</td>
      <td class="take-ms">{{item.take_ms}}</td>
      <td class="error">{{item.error}}</td>
    </tr>
    </tbody>
  </table>
</div>
