import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { LogsFilterDto } from "../../api/model/logsFilterDto";

@Component({
  selector: "app-logs-filter",
  templateUrl: "./logs-filter.component.html",
  styleUrls: ["./logs-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogsFilterComponent implements OnInit {
  form: FormGroup = new FormGroup({
    zombieId: new FormControl(),
    dateStart: new FormControl(),
    dateEnd: new FormControl(),
    likeSearch: new FormControl(),
    limit: new FormControl(100)
  });

  @Input() initParams!: LogsFilterDto;

  @Output() formChanged = this.form.valueChanges.pipe(debounceTime(1000));

  constructor() {}

  ngOnInit(): void {
    this.form.patchValue(this.initParams, { emitEvent: false });
  }
}
