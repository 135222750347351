<form [formGroup]="form">
  <mat-form-field style="width: 240px" appearance="fill">
    <mat-label>Zombie id</mat-label>
    <input type="text"
           placeholder="Pick one"
           aria-label="Number"
           matInput
           formControlName="zombieId"
           [matAutocomplete]="auto">
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option [value]="10">SaveProcessor</mat-option>
      <mat-option [value]="100">SYNC_LOGS</mat-option>
      <mat-option [value]="101">SYNC_MESSAGES</mat-option>
      <mat-option [value]="102">SYNC_METRICS</mat-option>
      <mat-option [value]="103">SYNC_PARTICIPANTS</mat-option>
      <mat-option [value]="104">SYNC_VIEWS</mat-option>
      <mat-option [value]="105">SYNC_ZOMBIE_REQUESTS</mat-option>
      <mat-option [value]="106">SYNC_MENTIONS</mat-option>
      <mat-option [value]="107">THREADS_CONTROLLER</mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field style="width: 240px; margin-left: 16px" appearance="fill">
    <mat-label>Пошук</mat-label>
    <input type="text"
           placeholder="Фраза для пошуку"
           matInput
           formControlName="likeSearch">
  </mat-form-field>

  <span class="spacer"></span>

  <mat-form-field appearance="fill">
    <mat-label>Період</mat-label>
    <mat-date-range-input [rangePicker]="rangePicker">
      <input formControlName="dateStart" matStartDate placeholder="Початок">
      <input formControlName="dateEnd" matEndDate placeholder="Кінець">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker>
      <mat-date-range-picker-actions>
        <button mat-button matDateRangePickerCancel>Скасувати</button>
        <button mat-raised-button color="primary" matDateRangePickerApply>Застосувати</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>
</form>
