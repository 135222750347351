import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Series, SeriesColumnOptions } from "highcharts";
import { GroupWindow, LagService } from "../../api";

@Component({
  selector: "app-lag-page",
  templateUrl: "./lag-page.component.html",
  styleUrls: ["./lag-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LagPageComponent implements OnInit {
  viewsHoursLag: SeriesColumnOptions[] | null = null;
  viewsDaysLag: SeriesColumnOptions[] | null = null;
  messagesNotSaved: SeriesColumnOptions[] | null = null;
  group = GroupWindow;
  groupCurrHours = GroupWindow.Day;
  groupCurrDays = GroupWindow.Day;
  groupCurrMessages = GroupWindow.Day;

  constructor(private api: LagService, private cd: ChangeDetectorRef, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle("Lags");

    this.loadHours(this.groupCurrHours);
    this.loadDays(this.groupCurrDays);
    this.loadNotSavedMessages(this.groupCurrMessages);
  }

  loadHours(ev: GroupWindow) {
    this.viewsHoursLag = null;
    this.groupCurrHours = ev;
    this.api.hoursLag(ev, [0.5, 0.9, 0.99]).subscribe(rs => {
      this.viewsHoursLag = this.mapColumns(rs, 60);
      this.cd.detectChanges();
    });
  }

  loadDays(ev: GroupWindow) {
    this.viewsDaysLag = null;
    this.groupCurrDays = ev;
    this.api.daysLag(ev, [0.5, 0.9, 0.99]).subscribe(rs => {
      this.viewsDaysLag = this.mapColumns(rs, 60);
      this.cd.detectChanges();
    });
  }

  loadNotSavedMessages(ev: GroupWindow) {
    this.messagesNotSaved = null;
    this.groupCurrMessages = ev;
    this.api.notSavedMessages(ev, [0.5, 0.9, 0.99]).subscribe(rs => {
      this.messagesNotSaved = [
        {
          name: "Всего",
          data: Array.from(rs).map((item: any) => {
            return [new Date(item.date).getTime(), Math.ceil(item.sum)];
          }),
          type: "column",
          color: "rgb(209,40,110)",
          tooltip: {
            valueDecimals: 0
          }
        }
      ];
      this.cd.detectChanges();
    });
  }

  mapColumns(rs: any[], divider = 60): SeriesColumnOptions[] {
    return [
      {
        name: "среднее",
        data: Array.from(rs).map((item: any) => {
          return [new Date(item.date).getTime(), Math.ceil(item.avg / divider)];
        }),
        type: "column",
        color: "rgb(155, 209, 40)",
        tooltip: {
          valueDecimals: 0
        }
      },
      {
        name: "медиана 50%",
        data: Array.from(rs).map((item: any) => {
          return [new Date(item.date).getTime(), Math.ceil(item.medians[0] / divider)];
        }),
        type: "column",
        color: "rgb(209,119,40)",
        tooltip: {
          valueDecimals: 0
        }
      },
      {
        name: "медиана 90%",
        data: Array.from(rs).map((item: any) => {
          return [new Date(item.date).getTime(), Math.ceil(item.medians[1] / divider)];
        }),
        type: "column",
        color: "rgb(61,183,85)",
        tooltip: {
          valueDecimals: 0
        }
      },
      {
        name: "медиана 99%",
        data: Array.from(rs).map((item: any) => {
          return [new Date(item.date).getTime(), Math.ceil(item.medians[2] / divider)];
        }),
        type: "column",
        color: "rgb(26,87,40)",
        tooltip: {
          valueDecimals: 0
        }
      },
      {
        name: "Max",
        data: Array.from(rs).map((item: any) => {
          return [new Date(item.date).getTime(), Math.ceil(item.max / divider)];
        }),
        type: "column",
        color: "rgb(209,40,60)",
        tooltip: {
          valueDecimals: 0
        },
        visible: false
      },
      {
        name: "Min",
        data: Array.from(rs).map((item: any) => {
          return [new Date(item.date).getTime(), Math.ceil(item.min / divider)];
        }),
        type: "column",
        color: "rgb(40,99,209)",
        visible: false,
        tooltip: {
          valueDecimals: 0
        }
      }
    ];
  }
}
