import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { ZombieService } from "../../api";

//Todo: edit zombie profile
//Todo: update zombie photo

@Component({
  selector: "app-zombie-edit-page",
  templateUrl: "./zombie-edit-page.component.html",
  styleUrls: ["./zombie-edit-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZombieEditPageComponent implements OnInit {
  get zombie() {
    return this.route.snapshot.data.zombie;
  }

  constructor(
    private route: ActivatedRoute,
    private api: ZombieService,
    private cd: ChangeDetectorRef,
    private title: Title
  ) {}

  public ngOnInit() {}

  async connect() {}
}
