<form [formGroup]="form">
  <mat-form-field style="width: 200px" appearance="fill">
    <mat-label>Zombie id</mat-label>
    <input type="text"
           placeholder="Pick one"
           aria-label="Number"
           matInput
           formControlName="zombieId">
  </mat-form-field>

  <mat-form-field style="width: 150px; margin-left: 16px" appearance="fill">
    <mat-label>Method</mat-label>
    <input type="text"
           placeholder="like search"
           matInput
           formControlName="method">
  </mat-form-field>

  <mat-form-field style="width: 150px; margin-left: 16px" appearance="fill">
    <mat-label>Request</mat-label>
    <input type="text"
           placeholder="like search"
           matInput
           formControlName="requestLike">
  </mat-form-field>
  <mat-form-field style="width: 150px; margin-left: 16px" appearance="fill">
    <mat-label>Take</mat-label>
    <input type="text"
           placeholder="> 100"
           matInput
           formControlName="takeMs">
  </mat-form-field>

  <mat-form-field style="width: 200px; margin-left: 16px" appearance="fill">
    <mat-label>Ошибка</mat-label>
    <mat-select formControlName="error">
      <mat-option [value]="undefined">Все</mat-option>
      <mat-option [value]="true">С ошибкой</mat-option>
      <mat-option [value]="false">Без ошибки</mat-option>
    </mat-select>
  </mat-form-field>

  <span class="spacer"></span>

  <mat-form-field appearance="fill">
    <mat-label>Період</mat-label>
    <mat-date-range-input [rangePicker]="rangePicker">
      <input formControlName="dateStart" matStartDate placeholder="Початок">
      <input formControlName="dateEnd" matEndDate placeholder="Кінець">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker>
      <mat-date-range-picker-actions>
        <button mat-button matDateRangePickerCancel>Скасувати</button>
        <button mat-raised-button color="primary" matDateRangePickerApply>Застосувати</button>
      </mat-date-range-picker-actions>
    </mat-date-range-picker>
  </mat-form-field>
</form>
