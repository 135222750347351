import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { StatusService } from "../../api";

@UntilDestroy()
@Component({
  selector: "app-status-page",
  templateUrl: "./status-page.component.html",
  styleUrls: ["./status-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusPageComponent implements OnInit {
  reqZombies$ = this.wrapReq(this.statusService.statusControllerZombiesStatus());
  reqNightStats$ = this.wrapReq(this.statusService.statusControllerTaskNightStats());
  reqMessagesParser$ = this.wrapReq(this.statusService.statusControllerTaskMessageParserStats());

  services = [
    "night_stats_loop",
    "message_parser",
    "username_and_link_invite_resolver",
    "clickhouse_sync_logs",
    "clickhouse_sync_metrics",
    "clickhouse_sync_participants_stats",
    "clickhouse_sync_views",
    "clickhouse_sync_zombie_requests",
    "clickhouse_sync_messages",
    "clickhouse_sync_mentions"
  ];
  buffers = [
    "zombie_logs_buffer",
    "metrics_buffer",
    "participants_stats_buffer",
    "views_clickhouse_sync_buffer",
    "requests_tracker_buffer",
    "mentions_buffer"
  ];
  buffSizes: any = {};
  servicesStatus: any = {};

  constructor(private statusService: StatusService) {}

  ngOnInit(): void {
    forkJoin(this.services.map(s => this.getServiceStatus(s))).subscribe(rs => {
      this.servicesStatus = rs.reduce((obj, curr, currentIndex) => {
        obj[this.services[currentIndex]] = curr;
        return obj;
      }, {} as any);
    });
    forkJoin(this.buffers.map(s => this.getBufferSize(s))).subscribe(rs => {
      console.log(rs);
      this.buffSizes = rs.reduce((obj, curr, currentIndex) => {
        console.log(curr);
        obj[this.buffers[currentIndex]] = curr;
        return obj;
      }, {} as any);
    });
  }

  wrapReq(req$: Observable<any>) {
    return req$.pipe(
      untilDestroyed(this),
      catchError(e => {
        if (e.error.message) {
          return of(e.error.message);
        }
        return of("error");
      })
    );
  }

  getServiceStatus(name: string) {
    return this.wrapReq(this.statusService.statusControllerGetServiceStatus(name));
  }

  getBufferSize(name: string) {
    return this.wrapReq(this.statusService.statusControllerGetBufferSize(name));
  }
}
