<div>
  <h1>Настройки колектора</h1>
</div>
<form [formGroup]="form">
  <div class="form-flex">
    <mat-form-field appearance="outline">
      <mat-label>Трекаємо від</mat-label>
      <input matInput type="number" placeholder="count" formControlName="subscriber_limit_participants_count">
      <mat-hint>Кількість підписників коли ми підписуємось на чат і трекаємо його</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Відписуємось якщо меньше</mat-label>
      <input matInput type="number" placeholder="count" formControlName="un_subscriber_limit_participants_count">
      <mat-hint>Якщо в чаті стало меньше підписників, тоді ми перестаємо його трекати</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Кількість діалогів</mat-label>
      <input matInput type="number" placeholder="count" formControlName="zombie_chats_limit">
      <mat-hint>Максимальна кількість діалогів на одного зомбі (канали і группи)</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Максимально на одного зомбі</mat-label>
      <input matInput type="number" placeholder="count" formControlName="max_chats_on_worker">
      <mat-hint>Максимально кількість чатів для трекінку одним зомбі</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Flood threshold</mat-label>
      <input matInput type="number" placeholder="count" formControlName="flood_chat_threshold">
      <mat-hint>Перестаємо трекати канал якщо він запостив більше повідомлень за один день, можлива аиністія</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Flood Hard threshold</mat-label>
      <input matInput type="number" placeholder="count" formControlName="flood_chat_hard_threshold">
      <mat-hint>Перестаємо трекати канал якщо він запостив більше повідомлень за один день, амністія не передбачена
      </mat-hint>
    </mat-form-field>
  </div>

  <div align="end">
    <button mat-flat-button color="primary" [disabled]="form.pristine || saving" (click)="save()">
      <mat-spinner *ngIf="saving"></mat-spinner>
      Сохранить настройки
    </button>
  </div>
</form>
