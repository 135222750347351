/**
 * Collector admin api
 * Collector stats observe and manage
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GroupWindow = 'day' | 'hour' | 'minute';

export const GroupWindow = {
    Day: 'day' as GroupWindow,
    Hour: 'hour' as GroupWindow,
    Minute: 'minute' as GroupWindow
};