import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";

export enum ZombieActionResult {
  OPEN_ZOMBIE_PAGE = "OPEN_ZOMBIE_PAGE",
  OPEN_ZOMBIE_LOGS_PAGE = "OPEN_ZOMBIE_LOGS_PAGE",
  FILTER = "FILTER"
}

@Component({
  selector: "app-select-zombie-action",
  templateUrl: "./select-zombie-action.component.html",
  styleUrls: ["./select-zombie-action.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectZombieActionComponent implements OnInit {
  constructor(private _bottomSheetRef: MatBottomSheetRef<SelectZombieActionComponent>) {}

  ngOnInit(): void {}

  openZombiePage() {
    this._bottomSheetRef.dismiss(ZombieActionResult.OPEN_ZOMBIE_PAGE);
  }

  openLogPage() {
    this._bottomSheetRef.dismiss(ZombieActionResult.OPEN_ZOMBIE_LOGS_PAGE);
  }

  filterByZombie() {
    this._bottomSheetRef.dismiss(ZombieActionResult.FILTER);
  }
}
