import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChatsPageComponent } from "./pages/chats-page/chats-page.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { LagPageComponent } from "./pages/lag-page/lag-page.component";
import { LogsPageComponent } from "./pages/logs-page/logs-page.component";
import { RequestsPageComponent } from "./pages/requests-page/requests-page.component";
import { SettingsPageComponent } from "./pages/settings-page/settings-page.component";
import { StatusPageComponent } from "./pages/status-page/status-page.component";
import { ZombieEditPageComponent } from "./pages/zombie-edit-page/zombie-edit-page.component";
import { ZombiePageComponent } from "./pages/zombie-page/zombie-page.component";
import { ZombiesPageComponent } from "./pages/zombies-page/zombies-page.component";
import { ZombieResolver } from "./resolvers/zombie.resolver";

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "/dashboard" },
  { path: "dashboard", component: DashboardPageComponent },
  { path: "zombies", component: ZombiesPageComponent },
  { path: "zombies/z/:zombieId", component: ZombiePageComponent, resolve: { zombie: ZombieResolver } },
  { path: "zombies/z/:zombieId/edit", component: ZombieEditPageComponent, resolve: { zombie: ZombieResolver } },
  { path: "logs", component: LogsPageComponent },
  { path: "requests", component: RequestsPageComponent },
  { path: "chats", component: ChatsPageComponent },
  { path: "settings", component: SettingsPageComponent },
  { path: "status", component: StatusPageComponent },
  { path: "lag", component: LagPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
